<template>
  <!-- dialog弹框 -->
  <el-dialog
    :title="this.dialogText.dialogTitle"
    :visible.sync="dialogVisible"
    width="570px"
    center
    @close="resetForm"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <!-- 表单区域 -->
    <el-form
      :model="dialogForm"
      :rules="dialogFormRules"
      ref="dialogFormRef"
      label-width="120px"
      class="demo-ruleForm"
      style="padding: 10px 30px 0 30px"
    >
      <el-form-item label="考试名称：" prop="name">
        <el-input
          v-model="dialogForm.name"
          placeholder="请输入考试名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="开考日期：" prop="beginTime">
        <!-- <el-input
          v-model="dialogForm.beginTime"
          placeholder="请选择开考日期"
        ></el-input> -->
        <el-date-picker v-model="dialogForm.beginTime" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期：" prop="endTime">
        <!-- <el-input
          v-model="dialogForm.endTime"
          placeholder="请选择结束日期"
        ></el-input> -->
        <el-date-picker v-model="dialogForm.endTime" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <div v-if="dialogText.dialogTitle === '创建考试'">
        <el-form-item label="考试科目：" prop="ids">
          <el-checkbox-group v-model="dialogForm.ids">
            <el-checkbox
              v-for="(item, index) in allObjectLists"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="监控视频：" prop="video">
          <el-radio-group v-model="dialogForm.video">
            <el-radio label="2">双路</el-radio>
            <el-radio label="1">单路</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传身份证：" prop="isSfz">
          <el-radio-group v-model="dialogForm.isSfz">
            <el-radio label="1">开</el-radio>
            <el-radio label="0">关</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传接口照片：" prop="isJkPhoto">
          <el-radio-group v-model="dialogForm.isJkPhoto">
            <el-radio label="1">开</el-radio>
            <el-radio label="0">关</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传环境照片：" prop="isHjPhoto">
          <el-radio-group v-model="dialogForm.isHjPhoto">
            <el-radio label="1">开</el-radio>
            <el-radio label="0">关</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="开启截图：" prop="isZb">
          <el-radio-group v-model="dialogForm.isZb">
            <el-radio label="1">开</el-radio>
            <el-radio label="0">关</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </div>
    </el-form>

    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submitForm"
        :disabled="isDisable"
        >{{ this.dialogText.btnText }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getAllSubjectData, createExam, updateExam } from "r/admin/examination";

export default {
  name: "ExamDialog",
  props: {
    dialogVisibleFu: Boolean,
    dialogText: Object,
    dialogForm: Object,
  },
  data() {
    return {
      allObjectLists: [], // 全部科目数据列表
      dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
      // dialogForm: this.dialogForm, // 数据对象
      dialogFormRules: {
        name: [{ required: true, message: "请输入考试名称", trigger: "blur" }],
        beginTime: [
          { required: true, message: "请选择开考时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        ids: [{ required: true, message: "请选择考试科目", trigger: "blur" }],
      }, // 表单验证规则
      isDisable: false, // 提交按钮是否可点击（防重复点击）
    };
  },
  created() {
    this.getAllObjectLists();
  },
  methods: {
    // 获取全部科目
    async getAllObjectLists() {
      const { data: res } = await getAllSubjectData({});
      // console.log(res);
      this.allObjectLists = res.data;
    },
    // 重置表单
    resetForm() {
      this.$refs.dialogFormRef.resetFields();
    },

    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.resetForm();
      this.$emit("closeDialog", this.dialogVisible);
    },

    // 确定,提交表单
    async submitForm() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      this.$refs.dialogFormRef.validate(async (valid) => {
        if (!valid) return;

        this.dialogForm.beginTime = this.dayjs(this.dialogForm.beginTime).format('YYYY-MM-DD')
        this.dialogForm.endTime = this.dayjs(this.dialogForm.endTime).format('YYYY-MM-DD')
        
        //验证开始时间是否小于结束时间
        // console.log( new Date(this.dialogForm.beginTime).getTime(), new Date(this.dialogForm.endTime).getTime(),"两个时间前后大小比较")
        if( new Date(this.dialogForm.beginTime).getTime() > new Date(this.dialogForm.endTime).getTime()){
            this.$message.error("开始时间必须早于结束时间！")
            return 
          //  this.$message({ 
          //     showClose: true,
          //     message: '开始时间必须早于结束时间',
          //     type: 'error',
          //     duration: 86400000
          //   });
          //   return
        }
        if (this.dialogText.dialogTitle === "修改考试") {
          // console.log("修 改");
          const { data: res } = await updateExam(this.dialogForm);
          // console.log(res);
          if (res.code !== 200) return this.$message.error(res.message);
          this.$message.success(res.message);
        } else if (this.dialogText.dialogTitle === "创建考试") {
          // console.log("创 建");
          if (this.dialogForm.ids.length > 0) {
            this.dialogForm.ids = this.dialogForm.ids.join(",");
          }
          const { data: res } = await createExam(this.dialogForm);
          // console.log(res);
          if (res.code !== 200) {
            this.dialogForm.ids = [];
            return this.$message.error(res.message);
          }
          this.$message.success(res.message);
        }

        this.cancelBtn();
      });
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";
</style>
