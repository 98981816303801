<template>
  <div class="continer">
    <!-- card面板 -->
    <el-card class="box-card">
      <!-- 头部标题 -->
      <div slot="header" class="clearfix">
        <h1>考试列表</h1>
      </div>

      <div class="text item">
        <!-- 头部标题与form表单 -->
        <div class="body-header">
          <el-row :gutter="20">
            <!-- span属性指的是占据多少列 -->
            <el-col :span="6">
              <el-button
                  type="primary"
                  icon="el-icon-plus"
                  class="hbtn"
                  @click="openCreateDialog()"
              >创建考试
              </el-button
              >
            </el-col>

            <el-col :span="18" style="float:right;">
              <el-input
                  v-model="queryInfo.examName"
                  placeholder="输入考试名称"
                  prefix-icon="el-icon-search"
                  clearable
                  @input="getExamInfoController()"
              ></el-input>
              <el-select
                  v-model="queryInfo.status"
                  placeholder="考试状态"
                  @change="getExamInfoController()"
              >
                <el-option label="考试状态" value=""></el-option>
                <el-option label="待开考" value="0"></el-option>
                <el-option label="考试中" value="1"></el-option>
                <el-option label="已结束" value="2"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>

        <!-- 主体table表格 -->
        <el-table :data="examInfoLists" style="width: 100%" stripe :max-height="tableHeight">
          <el-table-column
              align="center"
              prop="name"
              label="考试名称"
              min-width="210"
              fixed
          ></el-table-column>
          <el-table-column
              align="center"
              prop="beginTime"
              label="开考日期"
              min-width="180"
          ></el-table-column>
          <el-table-column
              align="center"
              prop="endTime"
              label="结束日期"
              min-width="180"
          ></el-table-column>
          <el-table-column
              align="center"
              prop=""
              label="考试状态"
              min-width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0" style="color: #67C23A;">待开考</span>
              <span v-else-if="scope.row.status === 1" style="color: #F56C6C;">考试中</span>
              <span v-else-if="scope.row.status === 2" style="color: #909399;">已结束</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" min-width="280" fixed="right">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  style="background-color:#563279;"
                  @click="openEditDialog(scope.row)"
              >修改考试
              </el-button
              >
              <el-button
                  size="mini"
                  style="background-color:#8B2D87;"
                  @click="examinationSubject(scope.row)"
              >编辑科目
              </el-button
              >
              <el-button
                  size="mini"
                  style="background-color:#DA8309;"
                  @click="deleteExamination(scope.row)"
              >删除考试
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- dialog弹框 -->
    <ExamDialog
        :dialogVisibleFu="dialogVisibleFu"
        :dialogText="dialogText"
        :dialogForm="dialogForm"
        @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import ExamDialog from "c/admin/examination/ExamDialog";
import {getExamData, deleteExam} from "r/admin/examination";

export default {
  name: "ExamLists",
  data() {
    return {
      queryInfo: {
        pageNum: 1, // 当前页数
        pageSize: 10, // 当前页显示的数据条数
        status: null, // 考试状态
        examName: "", // 考试名称
      },
      total: 0, // 总页数
      examInfoLists: [], // 考试数据列表
      formInline: [], // 表单数据
      // 弹框文字
      dialogText: {
        dialogTitle: "",
        btnText: "",
      },
      dialogVisibleFu: false, // 控制dialog弹框显示与隐藏
      dialogForm: {}, // 数据对象
      dialogFormRules: {}, // 表单验证规则
      tableHeight: ''
    };
  },
  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
    // 调用获取考试管理数据的方法
    this.getExamInfoController();
  },
  methods: {
    //定义方法，获取高度减去头尾
    getHeight() {
      this.tableHeight = window.innerHeight - 318 + "px";
    },

    // 获取考试管理数据
    async getExamInfoController() {
      // console.log(this.queryInfo);
      const {data: res} = await getExamData(this.queryInfo);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);

      res.data.records.forEach((item) => {
        if (item.beginTime) {
          item.beginTime = this.dayjs(item.beginTime).format(
              "YYYY-MM-DD"
          );
        }
        if (item.endTime) {
          item.endTime = this.dayjs(item.endTime).format("YYYY-MM-DD");
        }
      });
      this.examInfoLists = res.data.records;
      this.total = res.data.total;
    },

    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getExamInfoController();
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum;
      this.getExamInfoController();
    },

    // 打开创建对话框
    openCreateDialog() {
      this.dialogForm = {
        name: "",
        beginTime: "",
        endTime: "",
        ids: [],
        isSfz: "1",
        video: "2",
        isJkPhoto: "1",
        isHjPhoto: "1",
        // isZb: '1'
      };
      // console.log(this.dialogForm.ids.json(','));
      this.dialogVisibleFu = true;
      this.dialogText.dialogTitle = "创建考试";
      this.dialogText.btnText = "创 建";
    },

    // 打开修改对话框
    openEditDialog(data) {
      this.dialogForm = data;
      // console.log(data);

      this.dialogVisibleFu = true;
      this.dialogText.dialogTitle = "修改考试";
      this.dialogText.btnText = "修 改";
    },

    // 进入编辑科目页面
    examinationSubject(data) {
      // console.log(data, "进入编辑页");

      this.$router.push({
        name: "ExaminationSubject",
        query: data,
      });
    },

    // 关闭弹框
    closeDialog(value) {
      this.dialogVisibleFu = value;
      // 刷新表格
      this.getExamInfoController();
    },

    // 删除操作
    async deleteExamination(data) {
      const confirmResult = await this.$confirm(
          `确认删除 '${data.name}' ？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");
      // console.log(data);
      // 发送请求
      const {data: res} = await deleteExam({id: data.uuid});
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);

      this.getExamInfoController();
    },
  },

  components: {
    ExamDialog,
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/content";
@import "a/scss/table";
@import "a/scss/page";

</style>
